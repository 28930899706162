<template>
    <admin-page-template quick-access="top" class="h-p-admin-dashboard">

        <banner-selector
            event-label="banner_dashboard"
            banner-class="mb-8"
        />

        <alert-compact
            v-if="showRestoreAlert"
            variant="info"
            title="Restauração de hospedagens excluídas"
            btn-text="Restaurar"
            btn-link="/hosting/restore"
            icon-class="fas fa-trash-restore"
        >
            Existem backups de hospedagens excluídas recentemente que podem ser restauradas.
        </alert-compact>

        <div class="row no-gutters">
            <div class="col-xl-8">
                <card-hostings ref="cardHostings" class="mr-xl-4 mb-0" />
            </div>
            <div class="col-xl-4 mt-4 mt-xl-0">
                <card-tickets ref="cardTickets" class="h-card-tickets ml-xl-4 mb-0" />
            </div>
        </div>
    </admin-page-template>
</template>

<script>
    import { mapState }      from 'vuex'
    import AlertCompact      from "@/components/atoms/AlertCompact.vue";
    import BannerSelector    from '@/components/organisms/BannerSelector'
    import CardHostings      from '@/components/organisms/CardHostings'
    import CardTickets       from '@/components/organisms/CardTickets'
    import AdminPageTemplate from '@/components/templates/AdminPageTemplate'
    import AjaxService       from "@/services/AjaxService";

    export default {
        components: {
            AlertCompact,
            BannerSelector,
            CardHostings,
            CardTickets,
            AdminPageTemplate
        },
        data() {
            return {
                showRestoreAlert: false,
            }
        },
        computed: {
            ...mapState("user", [
                "user",
            ]),
        },
        beforeRouteLeave(to, from, next)
        {
            this.$refs.cardHostings.cancelFetch();
            this.$refs.cardTickets.cancelFetch();
            AjaxService.cancel();
            next();
        },
        created()
        {
            this.fetchRestore();
        },
        methods: {

            fetchRestore()
            {
                this.$api.get('/hosting/restore', { cancelToken: AjaxService.getCancelToken() })
                    .then((response) => { this.showRestoreAlert = response.data.data.length > 0 })
                    .catch((error) => { /* ignore */ })
            }
        }
    }
</script>

<style lang="stylus">
    .h-p-admin-dashboard {
        .h-card-tickets {
            @media (min-width 1200px) {
                height 100%
            }
        }
    }
</style>
