<template>
    <div>
        <banner-promotion
            v-if="showPromotionBanner"
            :expiration="promotionExpiration"
            link="/payment/manual-recharge?period=36"
            logo-img="/images/promotion/logo_christmas_2024.png"
            logo-img-alt="Natal 2024"
            button-text="Receba seu desconto agora"
            button-classes="btn-warning"
            event-category="campaign_christmas_2024"
            :event-label="eventLabel"
            v-bind:class="[bannerClass]"
            class="banner-christmas"
        >
            <template slot="content">
                <p class="mb-2">
                    A Hostoo está te presenteando<br>
                    com até 61% de desconto!
                </p>
            </template>
        </banner-promotion>

        <banner-promotion
            v-else-if="showTesterBanner"
            :expiration="promotionExpiration"
            link="/payment/manual-recharge"
            logo-img="/images/promotion/super-desconto.png"
            logo-img-alt="Super Desconto"
            event-category="campaign_tester"
            :event-label="eventLabel"
            v-bind:class="[bannerClass]"
            class="banner-tester"
        >
            <template slot="content">
                <p class="mb-3">
                    Ganhe até <strong>60% de desconto</strong><br>
                    contratando um dos nossos pacotes!
                </p>
                <div class="text-center">
                    <router-link v-if="showTesterButton" to="/hosting/create" class="btn btn-warning">
                        Ganhar agora
                    </router-link>
                </div>
            </template>
        </banner-promotion>
    </div>
</template>

<script>
    import { mapActions, mapState, mapGetters } from 'vuex'
    import BannerPromotion from '@/components/molecules/BannerPromotion'
    import BannerReferral  from '@/components/molecules/BannerReferral'

    export default {
        components: {
            BannerPromotion,
            BannerReferral
        },
        props: {
            bannerClass: {
                type: String,
                default: ""
            },
            showPromotion: {
                type: Boolean,
                default: true,
            },
            showTester: {
                type: Boolean,
                default: true,
            },
            showTesterButton: {
                type: Boolean,
                default: true,
            },
            showReferral: {
                type: Boolean,
                default: true,
            },
            eventLabel: {
                type: String,
                default: "",
            },
        },
        computed: {
            ...mapState("user", [
                "user",
                "promotion"
            ]),
            ...mapGetters("user", [
                "isActivePromotion",
                "isTesterPromotion",
                "isReferralPromotion"
            ]),

            showPromotionBanner: function() {
                return (this.showPromotion && this.isActivePromotion && !this.isTesterPromotion);
            },
            showTesterBanner: function() {
                return (this.showTester && this.isTesterPromotion);
            },
            // showReferralBanner: function() {
            //     return (this.showReferral && this.isReferralPromotion);
            // },

            promotionExpiration: function() {
                return (this.promotion && this.promotion.expiration) ? this.promotion.expiration : 0;
            },

            promotionBannerLink: function()
            {
                return (this.promotion.type && this.promotion.type === "bf2023_us")
                    ? null
                    : "/payment/manual-recharge";
            },
            promotionBannerAction: function()
            {
                return (this.promotion.type && this.promotion.type == "bf2023_us")
                    ? this.openChatForPromotion
                    : null;
            },
            promotionBannerText: function()
            {
                return (this.promotion.type && this.promotion.type == "bf2023_us")
                    ? "Seu site até 5x mais rápido em um servidor brasileiro.<br><strong>Taxa de migração zerada! 100% OFF</strong>"
                    : "Economize muito!<br>Ganhe até <strong>7 meses de hospedagem de sites grátis</strong>.";
            },
            promotionBannerButton: function()
            {
                return (this.promotion.type && this.promotion.type == "bf2023_us")
                    ? "Solicitar migração grátis"
                    : "Ganhe agora";
            },
        },
        created()
        {
            this.fetchPromotion();
        },
        methods: {
            ...mapActions({
                fetchPromotion: 'user/fetchPromotion',
            }),

            openChatForPromotion()
            {
                if (typeof window.jivo_api !== 'undefined')
                {
                    const message = "Olá! Quero saber mais sobre a promoção de Black Friday.";

                    if (jivo_config.chat_mode === "online")
                    {
                        jivo_api.showProactiveInvitation("", 1);
                        jivo_api.sendMessage(message);
                    }
                    else
                    {
                        jivo_api.open();
                        jivo_api.sendOfflineMessage({
                            name    : this.user.name,
                            email   : this.user.email,
                            message : message,
                        });
                    }
                }
                else {
                    alert("O suporte não foi iniciado. Por favor, aguarde alguns segundos e clique novamente. " +
                        "Se o problema persistir pedimos que atualize a sua página.");
                }
            }
        }
    }
</script>
