<template>
    <payment-template v-bind:class="{ 'promotion': usePromotionTemplate }">
        <card-recharge
            v-if="isAmountLoaded"
            :order="order"
            :amount="amount"
            :period="period"
            :monthly-cost="monthlyCost"
            :show-months="canShowMonths"
            :emergency-credit="isEmergencyCredit"
            v-on:recharged="onRecharged"
        />
    </payment-template>
</template>

<script>
    import {mapActions, mapGetters, mapState} from 'vuex'
    import AjaxService     from '@/services/AjaxService'
    import CardRecharge    from '@/components/organisms/CardRecharge'
    import PaymentTemplate from '@/components/templates/PaymentTemplate'
    import store from "@/store"
    import { Api } from '@/plugins/api'
    import ErrorService from "@/services/error.service";

    export default {
        components: {
            CardRecharge,
            PaymentTemplate
        },
        data() {
            return {
                order: null,
                amount: null,
                period: null,
                monthlyCost: 0,
                activeServices: 0,
                isAmountLoaded: false,
                isEmergencyCredit: false,
                isRechargeCompleted: false,
            }
        },
        computed: {
            ...mapState("user", [
                "promotion"
            ]),
            ...mapGetters("user", [
                "isActivePromotion",
                "isTesterPromotion"
            ]),

            usePromotionTemplate: function() {
                return (
                    this.isActivePromotion &&
                    (
                        !this.promotion.hasOwnProperty('available') ||
                        this.promotion.available
                    )
                );
            },
            canShowMonths: function() {
                return this.monthlyCost > 0 && this.activeServices >= 1;
            }
        },
        beforeRouteEnter(to, from, next)
        {
            // Check for order
            if (to.query.order)
            {
                const order = parseInt(String(to.query.order));

                // noinspection EqualityComparisonWithCoercionJS
                if (order.toString() != to.query.order) next();

                store.dispatch('loading/show');

                Api.get("/recharge/order/" + order)
                    .then(response => next(vm => {
                        vm.order  = response.data.data.id;
                        vm.amount = response.data.data.amount;
                        vm.isAmountLoaded = true;
                    }))
                    .catch(error => next(vm => ErrorService.handlePageError(error, vm)))
                    .then(() => store.dispatch('loading/hide'));
            }
            else
            {
                Api.get("/recharge/monthly-cost")
                    .then(response => next(vm => {
                        if (to.query.amount) {
                            vm.amount = parseFloat(String(to.query.amount));
                        }
                        else if (to.query.period) {
                            vm.period = parseInt(String(to.query.period));
                        }

                        vm.monthlyCost = response.data.data.cost;
                        vm.activeServices = response.data.data.activeServices;
                        vm.isAmountLoaded = true;

                        vm.getEmergencyCreditInfo();
                    }))
                    .catch(error => next(vm => ErrorService.handlePageError(error, vm)));
            }
        },
        beforeRouteLeave(to, from, next)
        {
            if ((this.order || from.query.order) && !this.isRechargeCompleted) {
                if (!confirm('A recarga não foi concluída. Se deixar esta página agora, o pedido poderá ser cancelado. Tem certeza que deseja continuar?')) return;
            }

            AjaxService.cancel();
            next();
        },
        mounted()
        {
            // Unload event
            window.addEventListener('unload', this.onPageUnload);

            // Tag de conversão do Facebook
            if (typeof window.fbq !== "undefined") {
                fbq('track', 'InitiateCheckout');
            }

            // Tag de conversão do Google Ads
            if (typeof window.gtag !== 'undefined') {
                gtag('event', 'conversion', {'send_to': 'AW-798302531/Gf8WCLnakcQBEMPC1PwC'});
            }
        },
        beforeDestroy()
        {
            window.removeEventListener('unload', this.onPageUnload);
        },
        methods: {
            ...mapActions({
                showLoading: 'loading/show',
                hideLoading: 'loading/hide',
                showErrorAlert: 'alert/error',
                fetchPromotion: 'user/fetchPromotion'
            }),

            /**
             * Obter informações sobre crédito emergencial do usuário.
             */
            getEmergencyCreditInfo()
            {
                this.$api.get('/recharge/emergency?check_invoices=0', { cancelToken: AjaxService.getCancelToken() })
                    .then (response => this.isEmergencyCredit = response.data.data.isAllowed)
                    .catch(error => {});
            },

            onRecharged()
            {
                this.isRechargeCompleted = true;
                setTimeout(() => this.$eventBus.$emit("credits-recharged"), 5000);
            },

            /**
             * Prevent from leaving page.
             * @param {Event} evt
             */
            onPageUnload(evt)
            {
                if ((this.order || this.from.query.order) && !this.isRechargeCompleted) {
                    if (!confirm('A recarga não foi concluída. Se deixar esta página agora, o pedido poderá ser cancelado. Tem certeza que deseja continuar?')) {
                        evt.preventDefault();
                        evt.returnValue = false;
                    }
                }
            }
        }
    }
</script>
