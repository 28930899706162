import { mapState } from "vuex";
import ModalDiscountHostings from "@/components/organisms/ModalDiscountHostings.vue";

export default {
    data() {
        return {
            $_discount_modal_id: "",
        }
    },
    computed: {
        ...mapState('user', ['user'])
    },
    methods: {
        showDiscountModal()
        {
            this.$_discount_modal_id = 'modal-discount-' + Math.round(Math.random() * 1000000);

            const html = this.$createElement(ModalDiscountHostings, {
                props: {
                    sites: this.user.activeServices,
                }
            });

            const modalTitle = this.user.activeServices >= 3
                ? "Você ganhou descontos especiais!"
                : "Falta pouco para você ganhar desconto!";

            this.$bvModal.msgBoxOk([html],
                {
                    id: this.$_discount_modal_id,
                    modalClass: "modal-discount-hostings",
                    title: modalTitle,
                    titleClass: "h4 font-family-title",
                    size: "md",
                    centered: true,
                    hideHeaderClose: true,
                    footerClass: "d-none",
                })
                .then (() => {})
                .catch(() => {});

        }
    },
}
