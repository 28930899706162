<template>
    <hosting-files-template :resource="resource">
        <card-table
            ref="cardBackups"
            inner-title="Restauração de Backup"
            hide-add
            hide-edit
            hide-filter
            hide-remove
            table-empty-text="Nenhum backup encontrado"
            :api-url="`/hosting/${resource}/backup`"
            :table-fields="tableBackupsFields"
        >
            <template slot="intro-text">
                Utilize esta funcionalidade para restaurar um backup de sua hospedagem realizado pelo sistema.
                Todos os seus arquivos, bancos de dados, subdomínios e outras configurações serão restaurados 
                para a data em que o backup foi realizado.
            </template>

            <template slot="table-date" slot-scope="{ rowData }">
                {{ rowData.date | moment("DD/MM/YYYY") }}
                <span v-if="rowData.date === $moment().format('YYYY-MM-DD')" class="text-muted pl-2">hoje</span>
                <span v-else class="text-muted pl-2">
                    {{ rowData.date | moment("from", $moment().startOf('day')) }}
                </span>
            </template>

            <template slot="dropdown-before" slot-scope="{ rowData }">
                <b-dropdown-item-button @click="showRestoreBackupConfirm(rowData)">
                    <i class="fas fa-history fa-fw"></i> Restaurar
                </b-dropdown-item-button>
            </template>
        </card-table>

        <card-title ref="dailyBackup" title="Backup Diário" inner-body>
            <template slot="intro-text">
                Contrate o backup diário e tenha mais previsibilidade e segurança para restaurar os dados de sua
                hospedagem quando precisar.
            </template>

            <text-loading v-show="isLoadingDailyBackup" />

            <div v-if="!isLoadingDailyBackup && hasDailyBackupStatus">
                <div v-if="dailyBackupStatus">
                    <div class="alert alert-success">
                        O backup diário está ativo em sua hospedagem!
                    </div>
                    <div class="form-buttons">
                        <button-form ref="btnDisableDailyBackup" variant="danger" @click="$refs.modalDisableDailyBackup.show()">
                            Desativar
                        </button-form>
                    </div>
                </div>
                <div v-else>
                    <div class="alert alert-danger">
                        O backup diário não está ativo em sua hospedagem.
                    </div>
                    <div class="form-buttons">
                        <button-form ref="btnEnableDailyBackup" variant="success" @click="$refs.modalEnableDailyBackup.show()">
                            Ativar backup diário
                        </button-form>
                    </div>
                </div>
            </div>
        </card-title>

        <b-modal ref="modalRestoreBackup" title="Restaurar backup" centered hide-footer hide-header-close no-close-on-backdrop no-close-on-esc>
            <form @submit.prevent="restoreBackup">
                <div class="form-group">
                    <label class="form-label d-inline">Data do backup:</label> {{ formData.date | moment("DD/MM/YYYY") }}
                </div>

                <form-group v-show="false" label="Defina os componentes da hospedagem a serem restaurados:" :error="formErrors.options">
                    <b-form-checkbox-group stacked v-model="formData.options">
                        <b-form-checkbox value="files">Arquivos</b-form-checkbox>
                        <b-form-checkbox value="databases">Bancos de Dados</b-form-checkbox>
                        <b-form-checkbox value="emails">E-mails</b-form-checkbox>
                    </b-form-checkbox-group>
                </form-group>

                <div class="alert alert-warning mb-0">
                    <strong>Atenção:</strong> Ao restaurar um backup os arquivos, bancos de dados, e-mails e outras
                    configurações feitas após a data do backup serão perdidos. Em caso de dúvidas entre em contato com
                    nosso Suporte.
                </div>

                <div class="form-buttons text-right">
                    <button ref="btnFormCancel" type="button" class="btn btn-secondary btn-action mr-2" @click="$refs.modalRestoreBackup.hide()">Cancelar</button>
                    <button-form ref="btnFormSubmit" type="submit" theme="success" class="btn-action">Restaurar</button-form>
                </div>
            </form>
        </b-modal>

        <modal-confirm
            ref="modalEnableDailyBackup"
            title="Ativar backup diário"
            title-class="text-center"
            @confirm="toggleDailyBackup(true)"
        >
            Este recurso possui um custo de <strong>R$ 10,00/mês</strong>.
            O valor será acrescido ao preço do plano atual de sua hospedagem.
            O serviço também será cobrado integralmente em hospedagens pausadas.
        </modal-confirm>

        <modal-confirm
            ref="modalDisableDailyBackup"
            title="Desativar backup diário"
            danger
            @confirm="toggleDailyBackup(false)"
        >
            <p class="mb-2">
                Ao desativar o backup diário, você estará abrindo mão de uma camada adicional de proteção
                que assegura a integridade dos dados existentes em sua hospedagem.
            </p>
            <p class="mb-0">
                Tem certeza que deseja desativar o backup diário?
            </p>
        </modal-confirm>
    </hosting-files-template>
</template>

<script>
    import { mapActions }       from 'vuex'
    import ModalConfirm         from '@/components/atoms/ModalConfirm'
    import CardTable            from '@/components/organisms/CardTable'
    import HostingFilesTemplate from '@/components/templates/HostingFilesTemplate'
    import ButtonForm from '@/components/atoms/ButtonForm'
    import FormGroup from '@/components/molecules/FormGroup'
    import ErrorService from '@/services/error.service'
    import CardTitle from "@/components/molecules/CardTitle.vue";
    import TextLoading from "@/components/atoms/TextLoading.vue";
    import AjaxService from "@/services/AjaxService";

    export default {
        props: ['resource'],
        components: {
            TextLoading,
            FormGroup,
            ButtonForm,
            ModalConfirm,
            CardTable,
            CardTitle,
            HostingFilesTemplate
        },
        data() {
            return {
                tableBackupsFields: [
                    {
                        name: '__slot:date',
                        sortField: 'date',
                        sortFunction: 'text',
                        title: 'Data'
                    }
                ],
                formData: {
                    date: null,
                    options: [],
                },
                formErrors: {},

                // Daily backup
                isLoadingDailyBackup: true,
                dailyBackupStatus: null,
            }
        },
        computed: {
            hasDailyBackupStatus: function () {
                return this.dailyBackupStatus != null;
            }
        },
        created()
        {
            this.fetchDailyBackup();
        },
        mounted()
        {
            if (this.$route.query.daily)
            {
                this.$nextTick(() => {
                    const elm = this.$refs.dailyBackup.$el;
                    window.scrollTo(0, elm.offsetTop);
                });
            }
        },
        beforeRouteLeave(to, from, next)
        {
            this.$refs.cardBackups.cancelFetch();
            AjaxService.cancel();
            next();
        },
        methods: {
            ...mapActions({
                showErrorAlert: 'alert/error',
                showLoading: 'loading/show',
                hideLoading: 'loading/hide',
            }),

            /**
             * Exibir confirmação de restauração de backup.
             */
            showRestoreBackupConfirm(item)
            {
                this.formData.date = item.date;
                this.formData.options = ['files', 'databases', 'emails'];
                this.$refs.modalRestoreBackup.show();
            },

            /**
             * Restaurar backup.
             */
            restoreBackup()
            {
                this.formErrors = {};

                this.$refs.btnFormSubmit.setLoadingAndFocus();
                this.$refs.btnFormCancel.disabled = true;

                this.$api.post(`/hosting/${this.resource}/backup/restore`, this.formData)
                    .then (response => {
                        window.location.href = '/hosting/' + this.resource;
                    })
                    .catch(error => {
                        ErrorService.handleFormError(error, this);
                        this.$refs.btnFormCancel.disabled = false;
                        this.$refs.btnFormSubmit.setLoading(false);
                    });
            },

            fetchDailyBackup()
            {
                this.isLoadingDailyBackup = true;

                this.$api.get(`/hosting/${this.resource}/backup/daily`, { cancelToken: AjaxService.getCancelToken() })
                    .then (response => { this.dailyBackupStatus = response.data.data.status })
                    .catch((error) => { this.showErrorAlert("Não foi possível obter o status do backup diário.") })
                    .then (() => { this.isLoadingDailyBackup = false });
            },

            toggleDailyBackup(status)
            {
                this.isLoadingDailyBackup = true;

                this.$api.post(`/hosting/${this.resource}/backup/daily`, { value: status })
                    .then (response => { this.dailyBackupStatus = response.data.data.status })
                    .catch((error) => { this.showErrorAlert(error) })
                    .then (() => { this.isLoadingDailyBackup = false });
            }
        }
    }
</script>