<template>
    <div class="questionnaire w-100 pt-2 pb-10" v-bind:class="[showQuestionnaire ? 'questionnaire-pinned' : 'questionnaire-unpinned']">    
        
        <b-row class="questionnaire-content mx-auto" v-if="!spinner && questionnaire">

            <div v-if="agreedParticipate">
                <button type="button" aria-label="Close" class="close">
                    <svg width="14" height="14" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg"  @click="closeQuestion">
                        <rect x="0.0366211" y="20.0005" width="27.2557" height="3.75358" rx="1.87679" transform="rotate(-45 0.0366211 20.0005)" fill="#CACACA"/>
                        <rect x="19.3093" y="22.6543" width="27.2557" height="3.75358" rx="1.87679" transform="rotate(-135 19.3093 22.6543)" fill="#CACACA"/>
                    </svg>
                </button>

                <div class="question-content mt-2 w-100">
                    <div class="mb-4 d-flex nav-question">
                        <svg v-if="currentQuestionIndex>0" @click="returnQuestion()" class="mr-4" width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21 9C21.5523 9 22 8.55228 22 8C22 7.44772 21.5523 7 21 7V9ZM0.292892 7.29289C-0.0976315 7.68342 -0.0976315 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM21 7L1 7V9L21 9V7Z" fill="#D9D9D9"/>
                        </svg>

                        <h6  v-if="questionnaire.type == 'nps' && answers['Q0']">Você avaliou como {{answers['Q0']}}</h6>
                        <h6  v-else-if="questionnaire.type != 'nps'">{{questionnaire.title}}</h6>
                    </div>

                    <h5>{{questionnaire.questions[currentQuestionIndex].title}}</h5>

                    <div class="mt-2 d-flex justify-content-between w-100" v-if="questionnaire.questions[currentQuestionIndex].type == 'quantitative'">
                        <div class="text-center" v-for="index in 10" :key="index">
                            <input type="radio" class="checkbox-round" :id="`quantitative-answer-${index}`" :value="index" v-model="quantitativeAnswerModel" @change="answer()"/>
                            <p>{{index}}</p>
                        </div>
                    </div>
                    <div class="mt-2 d-flex justify-content-between w-100 instruction" v-if="questionnaire.type == 'nps' && questionnaire.questions[currentQuestionIndex].type == 'quantitative'">
                        <p>Muito improvável</p>
                        <p>Extremamente provável</p>
                    </div>
                    <div class="mt-2 d-flex justify-content-between w-100 instruction" v-else-if="questionnaire.questions[currentQuestionIndex].type == 'quantitative'">
                        <p>{{questionnaire.questions[currentQuestionIndex].labelMin}}</p>
                        <p>{{questionnaire.questions[currentQuestionIndex].labelMax}}</p>
                    </div>

                    <div class="w-100" v-else-if="questionnaire.questions[currentQuestionIndex].type =='multiple-choice'">
                        <div class="multiple-choice d-flex flex-column w-100">
                            <div v-for="(option, index) in questionnaire.questions[currentQuestionIndex].choice" :key="index">
                                <input  
                                    type="checkbox" 
                                    class="btn-check" 
                                    :id="`objective-answer-${index}`" 
                                    :name="`objective-answer-${index}`"
                                    :value="option"
                                    @change="answerMultipleChoice(index, questionnaire.questions[currentQuestionIndex].max_selection || null)"
                                    hidden
                                />
                                <label class="btn-choice btn btn-outline-dark" :for="`objective-answer-${index}`">{{option}}</label>
                            </div>

                            <div v-if="questionnaire.questions[currentQuestionIndex].free_choice">
                                <input  
                                    type="checkbox"
                                    class="btn-check" 
                                    :id="`objective-answer-free`" 
                                    :name="`objective-answer-free`"
                                    :value="questionnaire.questions[currentQuestionIndex].free_choice"
                                    @change="answerMultipleChoice('free', questionnaire.questions[currentQuestionIndex].max_selection || null)"
                                    hidden
                                />
                                <label class="btn-choice btn btn-outline-dark" :for="`objective-answer-free`">{{ questionnaire.questions[currentQuestionIndex].free_choice }}</label>
                            </div>
                        </div>
                        
                        <input 
                            type="text" class="form-control" 
                            v-model="freeChoiceAnswerModel" 
                            v-if="freeChoice" 
                            placeholder="Digite aqui sua resposta" 
                        />

                    </div>

                    <div class="mt-2 d-flex justify-content-between w-100 instruction" v-if="questionnaire.questions[currentQuestionIndex].type == 'descriptive'">
                        <textarea class="form-control" v-model="descriptiveAnswerModel" :placeholder="questionnaire.questions[currentQuestionIndex].instructionPlaceholder ? questionnaire.questions[currentQuestionIndex].instructionPlaceholder : ''" rows="4"></textarea>
                    </div>

                </div>

                <div class="w-100 position-relative text-right questionnaire-footer">
                    <b-button v-if="slickDotsIndex == questionsLength" class="mt-3 mb-0 btn-send" @click="answer()" :disabled="isRequired">Enviar</b-button>
                    <b-button v-else-if="questionnaire.questions[currentQuestionIndex].type != 'quantitative'" class="mt-3 mb-3 btn-next" @click="answer()" :disabled="isRequired">Próxima</b-button>

                    <div class="mt-3 d-flex mx-auto mb-0 position-absolute slick-dots">
                        <div v-for="index in questionsLength" :key="index" class="mr-2">
                            <div :class="['dots', slickDotsIndex>=index ? 'answered' : '']"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else class="question-participate">
                <div class="question-content mt-2 w-100 text-center">
                    <h5>
                        Quer nos ajudar a melhorar? Dedique um minuto para responder nossa pesquisa.
                        <span class="d-none d-md-block"></span>Sua opinião é muito valiosa!
                    </h5>
                </div>
                <div class="mt-2 d-flex justify-content-center w-100">
                    <button type="button" class="btn btn-outline-dark mr-3" @click="notParticipate">Agora não, obrigado.</button>
                    <button type="button" class="btn btn-primary" @click="agreedParticipate=true">Sim, claro!</button>
                </div>
            </div>

        </b-row>

        <div class="d-flex justify-content-center m-5 p-5" v-else>
            <b-spinner></b-spinner>
        </div>
    </div>
</template>

<script>
    import {mapActions}  from 'vuex'

    export default {
        props: {
            questionnaireId: Number
        },

        data(){
            return {
                quantitativeAnswerModel:'',
                descriptiveAnswerModel:'',
                objectiveAnswerModel:'',
                multipleChoiceAnswerModel: [],
                freeChoiceAnswerModel: '',

                currentQuestionIndex: 0,
                nextQuestionIndex:1,
                previousQuestionIndex: 0,
                slickDotsIndex : 1,

                questionsLength: 0,

                questionnaire: null,
                answers:{},

                spinner: false,
                showQuestionnaire: false,

                isViewQuestionnaireStatic: false,
                agreedParticipate: false,

                freeChoice: false
            }
        },

        watch: {
            '$route' (to, from) {
                if( to.path.match(/^[/]hosting[/]/) && this.questionnaire )
                    this.showQuestionnaire = true;
            },

        },

        mounted(){

            var closed_questionnaire =  this.$cookie.get('closed_questionnaire');
            window.addEventListener('scroll', this.handleScroll);

            if(!closed_questionnaire && this.questionnaireId){
                this.$api.get(`/questionnaire/${this.questionnaireId}`)
                    .then (response => {
                        this.questionnaire = response.data.data;

                        for (const question of this.questionnaire.questions) {
                            if(!question.condition || question.condition.type == 'else')  
                                this.questionsLength ++;
                        }

                        setTimeout(() => { 
                            if(this.questionnaire) 
                                this.showQuestionnaire = true 
                        }, 20000);                        
                    })
                    .catch(error => {}); 
            }
            
        },

        computed: {
            isRequired(){
                // verifica se a pergunta atual é obrigatória e se o usuário já respondeu
                const isRequiredQuestion = !this.questionnaire.questions[this.currentQuestionIndex].optional;
                if (!isRequiredQuestion) return false;

                if (this.questionnaire.questions[this.currentQuestionIndex].type == 'quantitative') {
                    return this.quantitativeAnswerModel === '';
                } else if (this.questionnaire.questions[this.currentQuestionIndex].type == 'descriptive') {
                    return this.descriptiveAnswerModel === '';
                } else if (this.questionnaire.questions[this.currentQuestionIndex].type == 'multiple-choice') {
                    return this.multipleChoiceAnswerModel.length === 0;
                } else {
                    return this.objectiveAnswerModel === '';
                }
                
            }
        },

        methods:{
            ...mapActions({
                successNotification: 'notification/success',
            }),
            answer(){
                
                var question = this.questionnaire.questions[this.currentQuestionIndex];
                this.answers['Q'+(this.currentQuestionIndex)] = (question.type == 'quantitative') ? this.quantitativeAnswerModel : (question.type == 'descriptive') ? this.descriptiveAnswerModel : (question.type == 'multiple-choice') ? this.multipleChoiceAnswerModel : this.objectiveAnswerModel;
                
                //Verifica se foi uma pergunta de múltipla escolha
                //==========================================
                if (question.type == 'multiple-choice') {
                    if (question.free_choice && this.freeChoice) {
                        // remove a opção de escolha livre do array multipleChoiceAnswerModel e adiciona a nova resposta com prefixo 'question.free_choice'+': '
                        this.multipleChoiceAnswerModel = this.multipleChoiceAnswerModel.filter(item => !item.startsWith(question.free_choice));
                        this.multipleChoiceAnswerModel.push(question.free_choice+': '+this.freeChoiceAnswerModel);
                        this.answers['Q'+(this.currentQuestionIndex)] = this.multipleChoiceAnswerModel;
                    } 
                }

                //Verifica se foi a ultima pergunta
                //==========================================
                if( (this.questionnaire.questions.length <= this.nextQuestionIndex) || this.currentQuestionIndex == this.nextQuestionIndex)
                    return this.sendAnswers();

                this.slickDotsIndex++;
                this.previousQuestionIndex = this.currentQuestionIndex
                this.currentQuestionIndex = this.nextQuestionIndex;
                this.nextQuestionIndex++;

                var question = this.questionnaire.questions.indexOf(this.currentQuestionIndex) ? this.questionnaire.questions[this.currentQuestionIndex] : null;

                // Verifica se não é uma pergunta condicional
                // =========================================
                var dotsIncrementFlag = false; 
                var dotsDecrementFlag = false; 
                if(question.condition && question.condition.type == 'if'){
                    
                    dotsIncrementFlag = true;

                    // Passa para próxima pegrgunta até atender a condição da pergunta tual ou 
                    // terminar o bloco de perguntas condicional
                    // =========================================================================
                    while(question.condition && question.condition.type != 'else' && !this.checkCondition(this.answers['Q'+question.condition.indexQuestion], question.condition.operation, question.condition.value) ){
                        
                        //Verifica se exite uma resposta para a pergunta que deixará de ser exibida
                        //==========================================
                        if( this.answers[('Q'+this.currentQuestionIndex)] || this.answers[('Q'+this.currentQuestionIndex)] == '' ){
                            delete this.answers['Q'+this.currentQuestionIndex]

                            //aciona flag de ajuste de contagem visual de perguntas
                            dotsDecrementFlag = true;
                            dotsIncrementFlag = false;
                        }

                        
                        this.currentQuestionIndex = this.nextQuestionIndex;
                        this.nextQuestionIndex++;

                        //Verifica se não exite uma próxima pergunta
                        //==========================================
                        if(this.questionnaire.questions.length <= this.nextQuestionIndex)
                            this.nextQuestionIndex--;

                        question = this.questionnaire.questions.indexOf(this.currentQuestionIndex) ? this.questionnaire.questions[this.currentQuestionIndex] : null;
                
                        //verifica se foi ultima opção de pergunta 
                        //=======================================
                        if (!question){
                            return this.sendAnswers();
                        }
                    }
                    
                    // Busca a próxima pergunta fora do bloco de perguntas condicional
                    var nextQuestion = this.questionnaire.questions.indexOf(this.nextQuestionIndex) ? this.questionnaire.questions[this.nextQuestionIndex] : null;
                    while ( nextQuestion && nextQuestion.condition && (nextQuestion.condition.type == 'else if' ||  nextQuestion.condition.type == 'else') ) {
                        
                        // Verifica se exite uma resposta regesitrada para a pergunta do bloco condicional
                        // que não será exibida
                        //==========================================
                        if( this.answers[('Q'+this.nextQuestionIndex)] || this.answers[('Q'+this.nextQuestionIndex)] == '')
                            delete this.answers['Q'+this.nextQuestionIndex]
                        
                        this.nextQuestionIndex++;
                        nextQuestion = this.questionnaire.questions.indexOf(this.nextQuestionIndex) ? this.questionnaire.questions[this.nextQuestionIndex] : null;
                    }
                    
                    // desativa flag de ajuste contagem visual de perguntas
                    // em caso em bloco de perguntas condicionais que tem o tipo 'else'
                    // =================================================================
                    var aux = this.questionnaire.questions[this.nextQuestionIndex-1];
                    if( (question.condition && question.condition.type == 'else') ||  (aux.condition && aux.condition.type == 'else') ){
                        dotsDecrementFlag = false; 
                        dotsIncrementFlag = false;
                    }


                }

                if(!this.answers['Q'+this.currentQuestionIndex] && this.answers['Q'+this.currentQuestionIndex]!= '')
                    if( (!question.condition || question.condition.type != 'else' ) && dotsIncrementFlag)
                        this.questionsLength ++;
                
                if( (!question.condition) && dotsDecrementFlag)
                    this.questionsLength--;

                
                for (let index = 1; index <= 10; index++) {
                    let checkbox = document.getElementById(`quantitative-answer-${index}`);
                    if(checkbox)
                        checkbox.checked = false;
                }
                

                // Verifica se a pergunta já foi respondida e exibe a resposta
                // ===========================================================
                if(this.answers['Q'+this.currentQuestionIndex]){
                    if(question.type == 'quantitative')
                        this.quantitativeAnswerModel = this.answers['Q'+this.currentQuestionIndex];
                    else if(question.type == 'descriptive')
                        this.descriptiveAnswerModel = this.answers['Q'+this.currentQuestionIndex];
                    else if(question.type == 'multiple-choice'){
                        this.multipleChoiceAnswerModel = this.answers['Q'+this.currentQuestionIndex];
                        if (question.free_choice) {
                            this.freeChoice = this.multipleChoiceAnswerModel.some(item => item.startsWith(question.free_choice+': '));
                            if (this.freeChoice) {
                                // remove question.free_choice+': ' da string e atribui a freeChoiceAnswerModel
                                const freeChoiceAnswer = this.multipleChoiceAnswerModel.find(item => item.startsWith(question.free_choice+': '));
                                this.freeChoiceAnswerModel = freeChoiceAnswer.replace(question.free_choice+': ', '');
                                this.multipleChoiceAnswerModel = this.multipleChoiceAnswerModel.filter(item => !item.startsWith(question.free_choice));
                                this.multipleChoiceAnswerModel.push(question.free_choice);
                            }
                        }
                        this.showAnswerMultipleChoice(question);
                    }
                    else
                        this.objectiveAnswerModel = this.answers['Q'+this.currentQuestionIndex];
                }
                else{
                    this.quantitativeAnswerModel = '';
                    this.descriptiveAnswerModel = '';
                    this.objectiveAnswerModel = '';
                    this.answers['Q'+this.currentQuestionIndex] = ''
                    this.multipleChoiceAnswerModel = [];
                    this.freeChoiceAnswerModel = '';
                    this.freeChoice = false;

                    this.clearMultipleChoice();
                }
            },
            returnQuestion(){
                this.slickDotsIndex--;
                this.currentQuestionIndex = this.previousQuestionIndex;
                this.previousQuestionIndex --;
                this.nextQuestionIndex = this.currentQuestionIndex+1;

                if(this.previousQuestionIndex < 0)
                    this.previousQuestionIndex = 0;

                // Busca a  pergunta anterior fora do bloco de perguntas condicional
                var previousQuestion = this.questionnaire.questions.indexOf(this.previousQuestionIndex) ? this.questionnaire.questions[this.previousQuestionIndex] : null;
                while ( previousQuestion && previousQuestion.condition && (previousQuestion.condition.type == 'if' || previousQuestion.condition.type == 'else if' ||  previousQuestion.condition.type == 'else') ) {
                    
                    // Verifica se exite uma resposta regesitrada para a pergunta do bloco condicional
                    // que não será exibida
                    //==========================================
                    if( this.answers[('Q'+this.previousQuestionIndex)] )
                        delete this.answers['Q'+this.previousQuestionIndex]
                    
                    this.previousQuestionIndex--;
                    previousQuestion = this.questionnaire.questions.indexOf(this.previousQuestionIndex) ? this.questionnaire.questions[this.previousQuestionIndex] : null;
                }

                // Verifica se a pergunta já foi respondida e exibe a resposta
                // ============================================================
                var question = this.questionnaire.questions.indexOf(this.currentQuestionIndex) ? this.questionnaire.questions[this.currentQuestionIndex] : null;
                if(this.answers['Q'+this.currentQuestionIndex]){
                    if(question.type == 'quantitative')
                        this.quantitativeAnswerModel = this.answers['Q'+this.currentQuestionIndex];
                    else if(question.type == 'descriptive')
                        this.descriptiveAnswerModel = this.answers['Q'+this.currentQuestionIndex];
                    else if(question.type == 'multiple-choice'){
                        this.multipleChoiceAnswerModel = this.answers['Q'+this.currentQuestionIndex];
                        if (question.free_choice) {
                            this.freeChoice = this.multipleChoiceAnswerModel.some(item => item.startsWith(question.free_choice+': '));
                            if (this.freeChoice) {
                                // remove question.free_choice+': ' da string e atribui a freeChoiceAnswerModel
                                const freeChoiceAnswer = this.multipleChoiceAnswerModel.find(item => item.startsWith(question.free_choice+': '));
                                this.freeChoiceAnswerModel = freeChoiceAnswer.replace(question.free_choice+': ', '');
                                this.multipleChoiceAnswerModel = this.multipleChoiceAnswerModel.filter(item => !item.startsWith(question.free_choice));
                                this.multipleChoiceAnswerModel.push(question.free_choice);
                            }
                        }
                        this.showAnswerMultipleChoice(question);
                    }
                    else
                        this.objectiveAnswerModel = this.answers['Q'+this.currentQuestionIndex];
                }
                else{
                    this.quantitativeAnswerModel = '';
                    this.descriptiveAnswerModel = '';
                    this.objectiveAnswerModel = '';
                    this.multipleChoiceAnswerModel = [];
                    this.freeChoiceAnswerModel = '';
                    this.freeChoice = false;
                }

            },
            sendAnswers(){
                this.spinner = true;
                this.$api.post(`/questionnaire/${this.questionnaireId}/answer`, {answers : this.answers})
                    .then (response => {
                        this.successNotification({ message: 'Agradecemos a atenção. A sua resposta foi enviada com sucesso!' });
                    })
                    .catch(error => {})
                    .then( () => {
                        this.closeQuestion();
                    })

            },
            checkCondition(questionValue, condition, value){
                switch (condition) {
                    case '=':
                        return questionValue === value
                        break;

                    case '<=':
                        return questionValue <= value
                        break;
                    
                    case '>=':
                        return questionValue >= value
                        break;
                    
                    case '<':
                        return questionValue < value
                        break;

                    case '>':
                        return questionValue > value
                        break;
                
                    default:
                        break;
                }
            },
            closeQuestion(){
                this.$cookie.set('closed_questionnaire', true, { expires: '72h' });
                this.questionnaire = null;
                this.showQuestionnaire = false;
                this.spinner = false;
            },
            notParticipate(){
                this.answers = null;
                this.sendAnswers();
            },
            handleScroll() {

                if(this.showQuestionnaire){

                    var scrollMaxY = document.documentElement.scrollHeight - document.documentElement.clientHeight;

                    if (window.scrollY == scrollMaxY) {
                        document.querySelector(".questionnaire").classList.add('position-static');
                        window.scrollTo(0, window.scrollY + 80);

                        this.isViewQuestionnaireStatic = true;
                        
                    }

                    if  ( ( window.scrollY <= scrollMaxY - 180) && this.isViewQuestionnaireStatic ){
                        document.querySelector(".questionnaire").classList.remove('position-static');
                        this.isViewQuestionnaireStatic = false;
                    } 

                }
            },
            answerMultipleChoice(index, choiceLimit = null){

                // add or remove class to button
                var buttons = document.querySelectorAll('.btn-choice');

                setTimeout(() => {
                    
                    if(choiceLimit && this.multipleChoiceAnswerModel.length >= choiceLimit){
                        const input = document.getElementById(`objective-answer-${index}`);
                        input.checked = false;
                    }

                    this.multipleChoiceAnswerModel = [];
                    buttons.forEach((button, i) => {
                        const input = document.getElementById(button.getAttribute('for'));
                        const option = button.textContent;
                        
                        if(input.checked){
                            button.classList.add('btn-primary');
                            button.classList.remove('btn-outline-dark');

                            if (button.getAttribute('for') == `objective-answer-free`) {
                                this.freeChoice = true;
                            }

                            // verifica de option já foi inserida no array de respostas, multipleChoiceAnswerModel, e se não for adiciona a string no array 
                            //this.multipleChoiceAnswerModel = this.multipleChoiceAnswerModel.filter(item => item !== option);
                            this.multipleChoiceAnswerModel.push(option);

                        }else{
                            button.classList.remove('btn-primary');
                            button.classList.add('btn-outline-dark');
                            this.multipleChoiceAnswerModel = this.multipleChoiceAnswerModel.filter(item => item !== option);


                            if (button.getAttribute('for') == `objective-answer-free`) {
                                this.freeChoice = false;
                            }
                        }
                    });
                }, 150);
                
            },
            clearMultipleChoice(){
                var buttons = document.querySelectorAll('.btn-choice');
                buttons.forEach((button, i) => {
                    const input = document.getElementById(button.getAttribute('for'));
                    button.classList.remove('btn-primary');
                    button.classList.add('btn-outline-dark');
                    input.checked = false;
                });
                this.multipleChoiceAnswerModel = [];
                this.freeChoice = false;
                this.freeChoiceAnswerModel = '';
            },   
            showAnswerMultipleChoice(question){

                setTimeout(() => {
                
                    const buttons = document.querySelectorAll('.btn-choice');
                    buttons.forEach((button, i) => {
                        const input = document.getElementById(button.getAttribute('for'));
                        const option = button.textContent;
                        
                        if (this.multipleChoiceAnswerModel.includes(option)) {
                            button.classList.add('btn-primary');
                            button.classList.remove('btn-outline-dark');
                            input.checked = true;
                        }
                    });
                }, 150);
            }
        }
    }
</script>

<style lang="stylus">
    .questionnaire-unpinned{
        transform: translateY(100%);
    }
    .questionnaire-pinned {
        transform: translateY(0);
    }
    .questionnaire{
        position: fixed;
        background: white;
        box-shadow: 0 2px 20px rgba(black, .3);
        transition: transform .4s
        right: 0;
        bottom: 0;
        z-index: 10101;
        
        #modal-questionnaire{
            .modal-content{
                padding: 0rem 1rem 0rem 2rem !important;
                border-radius: 15px !important;
            }

            .modal-body{
                padding-bottom: 0 !important;
            }
        }
        
        .questionnaire-content{
            max-width: 600px;
            position: relative;

            p{
                margin-bottom: 0;
            }

            .close{
                position: absolute;
                top: 5;
                right: 0;
                opacity: 1;
            }
            .nav-question{
                h6{
                    font-style: normal;
                    font-weight: 700;
                    font-size: 1rem;
                    line-height: 16px;

                    color: #6149A7;
                }

                svg{
                    cursor:pointer;
                }
            }

            .questionnaire-footer{

                .btn-send, .btn-next{
                    background: #8D6BF2;
                    border-radius: 5px;
                }

                .slick-dots {
                    left: 50%;
                    top: 30%;
                    transform: translate(-50%, -0%)

                    .dots{
                        padding: 0;
                        width: 10px;
                        height: 10px;
                        border-radius: 2px;
                        background-color: #D9D9D9;
                    }

                    .answered{
                        background-color: #8D6BF2 !important;
                    }
                }
            }

            textarea{
                border: 1px solid #7E7E7E;
                border-radius: 10px;
                background-color: white;
            }
            
            textarea:focus{
                background-color: white;
            }

            h5{
                font-size: 0.9rem;
                font-style: normal;
                font-weight: 800;
                line-height: 12px;
                color: #464343;
                margin-bottom: 12px !important;
            }
            .question-participate{
                h5{
                    line-height: 24px !important;
                }
            }
            .checkbox-round {
                width: 1.125rem;
                height: 1.125rem;
                background-color: white;
                border-radius: 50%;
                border: 3px solid #8D6BF2;
                appearance: none;
                -webkit-appearance: none;
                outline: none;
                cursor: pointer;
            }

            .checkbox-round:checked {
                background-color: #8D6BF2;
            }

            .instruction{
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;

                color: #7E7E7E;
            }
        }

        
    }
</style>
