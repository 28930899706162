<template>
    <div v-if="sites < 3">
        <p v-if="sites == 0">
            Atualmente, você não possui sites hospedados conosco. Crie suas primeiras hospedagens e fique mais perto
            de desbloquear descontos mensais exclusivos.
        </p>
        <p v-else-if="sites == 1">
            Atualmente, você tem <strong>1 site</strong> hospedado conosco. Crie suas próximas hospedagens e fique mais
            perto de desbloquear descontos mensais exclusivos.
        </p>
        <p v-else-if="sites == 2">
            Atualmente, você tem <strong>2 sites</strong> hospedados conosco e está a um passo de desbloquear descontos
            mensais exclusivos.
        </p>

        <p class="font-weight-bold">
            O que você ganha ao trazer mais um site para a Hostoo?
        </p>

        <ul>
            <li><strong>Descontos garantidos:</strong> Economize todos os meses com 3 ou mais sites em sua conta.</li>
            <li><strong>Praticidade:</strong> Gerencie todos os seus sites com facilidade em um único painel.</li>
            <li><strong>Qualidade Hostoo:</strong> Conte com alto desempenho, suporte dedicado e recursos avançados.</li>
        </ul>

        <p class="mb-5">
            Adicione mais sites agora e garanta seu desconto.
        </p>

        <div class="row">
            <div class="col-6 text-center">
                <a href="/hosting/create" class="btn btn-primary btn-block">
                    Criar novo site
                </a>
            </div>
            <div class="col-6 text-center">
                <a href="/hosting/migration" class="btn btn-outline-primary btn-block">
                    Migrar meu site agora
                </a>
            </div>
        </div>
    </div>
    <div v-else>
        <p>
            Agora, com 3 ou mais sites hospedados na Hostoo, você economiza todos os meses enquanto desfruta de sites
            mais rápidos, estáveis e seguros.
        </p>

        <p class="font-weight-bold">
            Experimente também outros recursos exclusivos da Hostoo:
        </p>

        <ul>
            <li><strong>Acelerador de WordPress:</strong> Seu site WordPress até 57% mais rápido.</li>
            <li><strong>Backup diário:</strong> Seus dados ainda mais protegidos contra perdas ou invasões.</li>
        </ul>

        <p>
            Se tiver alguma dúvida, fale com um dos nossos consultores!
        </p>

        <div class="alert alert-warning mb-5">
            Para manter o desconto você deve ter ao menos <strong>3 sites ativos</strong> na Hostoo.
            Os sites pausados não contam como sites ativos.
        </div>

        <div class="row">
            <div class="col-6 text-center">
                <a href="/hosting/create" class="btn btn-primary btn-block">
                    Criar novo site
                </a>
            </div>
            <div class="col-6 text-center">
                <a href="/hosting/migration" class="btn btn-outline-primary btn-block">
                    Migrar meu site agora
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        sites: {
            type: Number,
            default: 0
        }
    },
    methods: {
        openSupport()
        {
            let messageSent = false;

            if (typeof window.jivo_api !== 'undefined')
            {
                const message = "Quero saber mais sobre os descontos ao criar novos sites.";

                if (jivo_config.chat_mode === "online")
                {
                    jivo_api.showProactiveInvitation("", 1);
                    jivo_api.sendMessage(message);
                    messageSent = true;
                }
            }

            if (!messageSent) {
                window.location.href = "/support/tickets";
            }
        }
    }
}
</script>