import { mapActions, mapState } from 'vuex'
import ReferralShare from '@/components/organisms/ReferralShare'

export default {
    data() {
        return {
            $_referral_modal_id: "",
            referrer: {},
        }
    },
    computed: {
        ...mapState('user', ['referral', 'isLoadingReferral'])
    },
    mounted()
    {
        this.fetchReferral();
    },
    methods: {
        ...mapActions({
            fetchReferral: 'user/fetchReferral'
        }),

        /**
         * Exibir modal com informações sobre o programa de indicações.
         */
        showReferralModal()
        {
            this.$_referral_modal_id = 'modal-referral-' + Math.round(Math.random() * 1000000);

            const html = this.$createElement(ReferralShare, { props: { link: this.referral.link, readMore: true } });

            this.$bvModal.msgBoxOk([html],
                {
                    id: this.$_referral_modal_id,
                    modalClass: "modalReferral",
                    title: "Indique a Hostoo e ganhe bônus!",
                    titleClass: "h4 font-family-title",
                    size: "md",
                    centered: true,
                    hideHeaderClose: true,
                    footerClass: "d-none",
                })
                .then (() => {})
                .catch(() => {});
        }
    }
}
